























import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: {
    OEventProgram: () =>
      import("@/components/organisms/event/eventProgram/o-event-program.vue"),
  },

  setup() {
    const type = "edit";

    return { type };
  },
});
